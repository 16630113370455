exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categoria-js": () => import("./../../../src/pages/categoria.js" /* webpackChunkName: "component---src-pages-categoria-js" */),
  "component---src-pages-estudio-js": () => import("./../../../src/pages/estudio.js" /* webpackChunkName: "component---src-pages-estudio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-premios-js": () => import("./../../../src/pages/premios.js" /* webpackChunkName: "component---src-pages-premios-js" */),
  "component---src-pages-publicaciones-js": () => import("./../../../src/pages/publicaciones.js" /* webpackChunkName: "component---src-pages-publicaciones-js" */),
  "component---src-pages-subcategoria-js": () => import("./../../../src/pages/subcategoria.js" /* webpackChunkName: "component---src-pages-subcategoria-js" */)
}

